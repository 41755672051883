<template>
	<!-- begin:: Aside -->
	<div class="brand flex-column-auto" id="kt_brand" ref="kt_brand">
		<div class="brand-logo">
			<router-link to="/">
				<img :src="siteLogo()" alt="Logo" />
			</router-link>
		</div>
		<div class="brand-tools" v-if="allowMinimize">
			<button
				class="brand-toggle btn btn-sm px-0"
				id="kt_aside_toggle--"
				ref="kt_aside_toggle--"
				@click="toggleAsideState"
			>
				<span class="svg-icon svg-icon svg-icon-xl">
					<inline-svg
						class="svg-icon"
						:src="$assetURL('media/svg/icons/Navigation/Angle-double-left.svg')"
					/>
				</span>
			</button>
		</div>
	</div>
	<!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
	outline: none;
}
</style>

<script>
import KTCookie from "@/assets/js/components/cookie.js";
import { mapGetters } from "vuex";
import objectPath from "object-path";
import KTLayoutBrand from "@/assets/js/layout/base/brand.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import ApiService from "@/core/services/api.service";
import { SET_SIDE_MENU } from "@/core/services/store/config.module";
export default {
	name: "KTBrand",
	mounted() {
		// Init Brand Panel For Logo
		KTLayoutBrand.init(this.$refs["kt_brand"]);

		// Init Aside Menu Toggle
		KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
	},
	methods: {
		siteLogo() {
			const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
			// set brand logo
			const logoObject = this.layoutConfig("self.logo");

			let logo;
			if (typeof logoObject === "string") {
				logo = logoObject;
			}
			if (typeof logoObject === "object") {
				logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
			}
			if (typeof logo === "undefined") {
				const logos = this.layoutConfig("self.logo");
				logo = logos[Object.keys(logos)[0]];
			}
			return process.env.BASE_URL + logo;
		},
		toggleAsideState() {
			const body = document.querySelector("body");
			let side_menu = "max";
			if (body.classList.contains("aside-minimize")) {
				KTCookie.setCookie("kt_aside_toggle_state", "on");
				side_menu = "max";
				body.classList.remove("aside-minimize");
			} else {
				KTCookie.setCookie("kt_aside_toggle_state", "off");
				side_menu = "min";
				body.classList.add("aside-minimize");
			}
			this.toggleAsideMenu(side_menu);
		},
		toggleAsideMenu(side_menu) {
			ApiService.setHeader();
			ApiService.post("update-side-menu");
			this.$store.commit(SET_SIDE_MENU, side_menu);
		},
		getCookie(cname) {
			let name = cname + "=";
			let ca = document.cookie.split(";");
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) == " ") {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		},
	},
	computed: {
		...mapGetters(["layoutConfig", "getProfile"]),

		allowMinimize() {
			return !!this.layoutConfig("aside.self.minimize.toggle");
		},
	},
};
</script>
